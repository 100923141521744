import {customElement, bindable, inject, BindingEngine} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import * as _ from "lodash";
import {TranslatableService} from "./translatable-service";

@customElement('sio-cell-translatable')
@inject(BindingEngine, TranslatableService)
export class Translatable extends AbstractCell
{
    @bindable column;
    @bindable item;

    //Specifically set clipped only for cells
    @bindable clipped = false;

    constructor(bindingEngine, translatableService) {
        super();

        this.bindingEngine = bindingEngine;
        this.translatableService = translatableService;
    }

    itemChanged()
    {
        this.bind();
    }

    bind()
    {
        this.value = this.getValue();
        this.options = _.assign({}, this._defaultOptions, this.column.options);

        const multiple = this.column?.options?.multiple;

        if (multiple) {
            this.locales = this.value && this.value.length > 0 ? Object.keys(this.value[0]) : [];
        } else {
            this.locales = this.value ? Object.keys(this.value) : [];
        }

        this.setValue(this.column?.history);
    }

    setValue(history)
    {
        const multiple = this.column?.options?.multiple;
        const originalValue = multiple ? this.value : (this.value ? [this.value] : []);
        let value = [];

        for (const val of originalValue ?? []) {

            if (history) {
                for (const localeValue in val) {
                    if (val.hasOwnProperty(localeValue)) {
                        value.push(localeValue + ': ' + val[localeValue]);
                    }
                }
            } else {
                const translation = this.translatableService.getTranslation(val);
                if (translation) {
                    value.push(translation);
                }
            }
        }
        this.currentValue = value;
    }

    setClipped(clipped)
    {
        this.clipped = clipped;
    }
}
