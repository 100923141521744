import {bindable, customElement, inject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {InlineEditorDialog} from "./inline-editor-dialog/inline-editor-dialog";
import getPropertyByPath from "../utilities/get-property-by-path";
import * as _ from "lodash";

@customElement('sio-cell')
@inject(
    DialogService
)
export class Cell {
    @bindable column;
    @bindable item;
    //Optional complete list
    @bindable list;

    constructor(dialog) {
        this.dialog = dialog;
    }

    /*public*/
    openInlineEditor() {
        this.dialog.open(
            {
                viewModel: InlineEditorDialog,
                model: {
                    column: this.column,
                    item: this.item,
                },
                lock: true,
                overlayDismiss: false
            }
        ).whenClosed();
    }

    columnChanged() {
        const context = _.cloneDeep(this.column);

        context.property = null;

        this.columnWithoutProperty = context;
    }

    getItems(item, property) {

        if (!property) {
            return item != null ? [item] : [];
        }

        let value = getPropertyByPath(item, property, true);

        if (value == null) {
            return [];
        }

        // Remove undefined entries to prevent rendering multiple <br> 
        let filteredValue = Array.isArray(value) ? value.filter(entry => entry !== undefined) : [value];        

        return filteredValue;
    }

    getItem() {
        return this.item;
    }

    getColumn() {
        return this.column;
    }
}
